<template>
  <div>
    <div class="elesigncode-content">
      <vue-ele-sign
          ref="elesigncode"
          direction="direction"
          :pen="pen"
          :imgType="imgType"
          :readOnly="readOnly"
          :vsConsole="true"
          class="sign"
      />
      <div class="btn-box">
        <el-button size="small" type="primary" plain circle @click="setPen">切换画笔</el-button>
        <el-button size="small" type="primary" plain circle @click="onClear">撤销</el-button>
        <el-button size="small" type="primary" plain circle @click="onClearSign">清空签名</el-button>
        <el-button size="small" type="primary" plain circle @click="getImg">获取图片</el-button>
        <el-button size="small" type="primary" plain circle @click="getFile">获取文件</el-button>
        <el-button size="small" type="warning" plain circle @click="uploadSuccess">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueEleSign from "vue-ele-sign";
import {getToken} from "@/utils/auth";
export default {
  components: {
    VueEleSign,
  },
  data() {
    return {
      pen: "default",
      imgType: "png",
      readOnly: false,
      direction: "left", //left :向左横屏
      // 图片上传部分
      is_private: 1,
      postUrl: '/api/auth/upload/company',
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
    };
  },
  methods: {
    setPen() {
      this.pen = this.pen == "default" ? "writing" : "default";
    },
    onClear() {
      //   this.$refs.elesigncode.clear();
      this.$refs.elesigncode.undo();
    },
    onClearSign() {
      this.$refs.elesigncode.clearSign();
    },
    getImg() {
      let isEmpty = this.$refs.elesigncode.isEmpty();
      if (isEmpty) {
        this.$message.error("请签名之后提交！");
      } else {
        this.$refs.elesigncode.getImg().then(res => {
          console.log("图片地址1", res);

        })
      }
    },
    getFile() {
      let isEmpty = this.$refs.elesigncode.isEmpty();
      if (isEmpty) {
        this.$message.error("请签名之后提交！");
      } else {
        this.$refs.elesigncode.getFile().then((res) => {
          console.log("文件1", res);
        });
      }
    },
    uploadSuccess(res, file) {

    }
  },
};
</script>

<style scoped>
.elesigncode-content {
  width: 700px;
  min-height: 500px;
  margin: auto;
}
.sign {
  border: 1px solid #3A3939;
}
.btn-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
